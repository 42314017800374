import React, { useRef, useState, useEffect, useCallback } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionPaddings,
  brandFonts,
  fontSize,
  brandColours,
} from '../styles';
import { Container, Link } from './ui';
import { H1, Typography } from './typography/Typography';

const StyledNotFound = styled.section`
  ${sectionPaddings()};
`;

const StyledInner = styled.div`
  text-align: center;

  ${minBreakpointQuery.smedium`
    display: grid;
    justify-content: center;
    align-items: center;
  `}
`;

const StyledImageWrapper = styled.div`
  margin: 0 auto;
  width: 35vw;
  max-width: 430px;
  background-color: ${brandColours.secondary};

  ${minBreakpointQuery.smedium`
    z-index: 1;
    grid-row: 1/2;
    grid-column: 1/2;
    margin: auto;
  `}
`;

const StyledImage = styled(GatsbyImage)`
  filter: grayscale(1);
  mix-blend-mode: screen;
`;

const StyledContent = styled.div`
  ${maxBreakpointQuery.smedium`
    margin-top: 50px;
    margin-right: auto;
    margin-left: auto;
    max-width: 600px;
  `}

  ${minBreakpointQuery.smedium`
    position: relative;
    grid-row: 1/2;
    grid-column: 1/2;
  `}
`;

const StyledOverline = styled(props => (
  <Typography {...props} as="p" variant="H5" />
))`
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  text-transform: uppercase;

  ${minBreakpointQuery.medium`
    margin-bottom: 40px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 50px;
  `}

  ${minBreakpointQuery.xxlarge`
    margin-bottom: 60px;
  `}
`;

const StyledHeading = styled(H1)``;

const StyledLink = styled(Link)`
  margin-top: 50px;
  font-family: ${brandFonts.tertiary};
  ${fontSize(13)};
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: underline;

  ${minBreakpointQuery.small`
    ${fontSize(14)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(15)};
  `}
`;

const NotFound = ({ heading, images }) => {
  const mouseMoveArea = useRef(null);
  const totalDistance = useRef(null);
  const oldCursorX = useRef(null);
  const oldCursorY = useRef(null);

  const [image, setImage] = useState(images[0]);

  const changeImage = useCallback(() => {
    const randomNumber = Math.floor(Math.random() * images.length);
    setImage(images[randomNumber]);
  }, [images]);

  const mouseMoveEvent = useCallback(
    e => {
      const cursorThreshold = 100;

      if (oldCursorX.current) {
        totalDistance.current += Math.sqrt(
          Math.pow(oldCursorY.current - e.clientY, 2) +
            Math.pow(oldCursorX.current - e.clientX, 2)
        );
      }

      if (totalDistance.current >= cursorThreshold) {
        changeImage();
        totalDistance.current = 0;
      }

      oldCursorX.current = e.clientX;
      oldCursorY.current = e.clientY;
    },
    [changeImage]
  );

  useEffect(() => {
    const multipleImages = images.length > 1;
    const isMouseDevice = matchMedia('(pointer:fine)').matches;

    const currentMouseMoveArea = mouseMoveArea.current;

    if (multipleImages) {
      if (isMouseDevice) {
        currentMouseMoveArea.addEventListener('mousemove', mouseMoveEvent);
      } else {
        window.setInterval(changeImage, 2000);
      }
    }

    return () => {
      if (multipleImages && isMouseDevice) {
        currentMouseMoveArea.removeEventListener('mousemove', mouseMoveEvent);
      }
    };
  }, [images, mouseMoveArea, changeImage, mouseMoveEvent]);

  return (
    <StyledNotFound ref={mouseMoveArea}>
      <Container wide={true}>
        <StyledInner>
          <StyledImageWrapper>
            <StyledImage image={image.gatsbyImageData} alt={image.alt} />
          </StyledImageWrapper>
          <StyledContent>
            <StyledOverline>404 Error</StyledOverline>
            <StyledHeading>{heading}</StyledHeading>
          </StyledContent>
          <StyledLink to="/">Back to Home</StyledLink>
        </StyledInner>
      </Container>
    </StyledNotFound>
  );
};

export default NotFound;
