import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/Layout';
import NotFound from '../components/NotFound';

const NotFoundPage = () => {
  const {
    datoCmsNotFound: { title, images },
  } = useStaticQuery(graphql`
    query NotFoundPageQuery {
      datoCmsNotFound {
        title
        images {
          gatsbyImageData(width: 430, height: 575)
          alt
        }
      }
    }
  `);

  return (
    <Layout noIndex={true}>
      <main>
        <NotFound heading={title} images={images} />
      </main>
    </Layout>
  );
};

export default NotFoundPage;
